import Header from "../components/Haeder";
import Footer from "../components/Footer";
import Card from "../components/Card";
import { useState } from "react";
import React from 'react'
// import Lottie from 'react-lottie-player'
import Lottie from "lottie-web";
import { useEffect,useRef } from "react";


const EnbillUserPage = () => {
    const [showCard1, setShowCard1] = useState(false);
    const [showCard2, setShowCard2] = useState("");
    const [showCard3, setShowCard3] = useState("");
    const [showCard4, setShowCard4] = useState("");
    const container2=useRef(null);
    const container3=useRef(null);
    const container4=useRef(null);
    useEffect(()=>{
        Lottie.loadAnimation({
            container:container2.current,
            renderer:"svg",
            loop:true,
            autoplay:true,
            animationData: require("../Assets/Images/animation2.json")
        });
        return()=>{
            Lottie.destroy();
        };
    },[])
    useEffect(()=>{
        Lottie.loadAnimation({
            container:container3.current,
            renderer:"svg",
            loop:true,
            autoplay:true,
            animationData: require("../Assets/Images/animation2.json")
        });
        return()=>{
            Lottie.destroy();
        };
    },[])
    useEffect(()=>{
        Lottie.loadAnimation({
            container:container4.current,
            renderer:"svg",
            loop:true,
            autoplay:true,
            animationData: require("../Assets/Images/animation2.json")
        });
        return()=>{
            Lottie.destroy();
        };
    },[])
    return (
        <>
            <Header></Header>
            <div className="row" style={{ padding: "5%", backgroundColor: "#F5F5F5", width: "100%" }}>

                <div className="col-lg-8 " style={{ padding: "5%", display: "flex", flexDirection: "column", justifyContent: "left" }}>
                    <h1 className="" style={{ marginTop: "-4%", textAlign: "left", marginRight: "20px" }}>Reconnecting to Your Favourite Local Dukaan</h1>
                    <p className="mt-5" style={{ width: "60%", textAlign: "left" }}>You can now find all of your local stores on your phone. We will
                        help you save time and provide you with the convenience of doing
                        your shopping near your location. Review their inventory, book
                        products online, and choose between home delivery and pickup.
                        Find nearby stores and manage your billing and Udhaar all in one
                        place.</p>
                    <a href="https://play.google.com/store/apps/details?id=com.indotechsj.userapp" target="_blank" className="mt-4" style={{textDecoration:"none",display:"flex",justifyContent:"center",alignItems:"center", backgroundColor: "#EE7A37", color: "white", height: "40px", width: "168px", borderRadius: "28px", border: "none" }} >DOWNLOAD NOW</a>
                </div>
                <div className="col-lg-4 ">
                    <img className="img-fluid" style={{ height: "331px", width: "177px", transform: "rotate(-8deg)" }} src={require("../Assets/Images/phone3.png")} />
                </div>



            </div>
            <div style={{width:"100%"}}>
                <p style={{ color: "#EE7A37", font: "normal normal bold 20px/26px Roboto", marginTop: "10px" }}>Know App Features</p>
            </div>
            <div className="row" style={{ padding: "1% 3%" ,width:"100%"}}>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo1.png")} />
                    </div>
                    <p style={{ color: "#EE7A37", font: "normal normal bold 17px/25px Noto Sans CJK KR" }}>Online Convenience</p>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins" }}>Enbill provides an online presence for all your local favourites.
                        Download the app and find local stores like Kirana stores,
                        vegetable vendors, or tea shops around your 15-km radius. A
                        digital showcase catalogue solves many problems, as it will also
                        give you access to local markets near you. As a customer, you can
                        view the digital catalogue of any store on your mobile screen. It
                        will save you time and provide you with easy access to all of the
                        products available within the store.</p>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo2.png")} />
                    </div>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div ref={container2} >
                       
                    </div>

                </div>

            </div>
            <div className="gradiantbar" style={{ height: "30px", width: "100%", opacity: "39%" }}>

            </div>
            <div className="row" style={{ padding: "3% 5%",width:"100%" }}>
                <div className="col-md" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo4.png")} />
                    </div>

                </div>
                <div className="col-md">
                    <div  >
                        <img className="img-fluid" src={require("../Assets/Images/mlogo5.png")} />
                    </div>
                    <p style={{ color: "#EE7A37", font: "normal normal bold 17px/25px Noto Sans CJK KR" }}>Financial Guide</p>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins" }}>Now buy products on Udhaar from your favourite shopkeepers
                        and pay later! Udhaar sends you regular reminders, making paying
                        your debts more efficient. Enbill also provides you your own
                        passbook, which helps you keep track of all the purchases you
                        make.</p>


                </div>

            </div>
            <div className="gradiantbar" style={{ height: "30px", width: "100%", opacity: "39%" }}>

            </div>
            <div className="row" style={{ padding: "3% 5%" ,width:"100%"}}>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo1.png")} />
                    </div>
                    <p style={{ color: "#EE7A37", font: "normal normal bold 17px/25px Noto Sans CJK KR" }}>Live Location:</p>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins" }}>Enbill's live location feature has made the customers' lives easier
                        as they can find a particular product or a shop with Enbill’s
                        location feature. You can get the shop’s live location and reach the
                        shop easily. This feature has removed the hassle of finding the
                        shop and made your life easier.</p>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo2.png")} />
                    </div>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div ref={container3} >
                     
                    </div>

                </div>

            </div>
            <div className="gradiantbar" style={{ height: "30px", width: "100%", opacity: "39%" }}>

            </div>
            <div className="row" style={{ padding: "3% 5%",width:"100%" }}>
                <div className="col-md" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo4.png")} />
                    </div>

                </div>
                <div className="col-md" >
                    <div  >
                        <img className="img-fluid" src={require("../Assets/Images/mlogo5.png")} />
                    </div>
                    <p style={{ color: "#EE7A37", font: "normal normal bold 17px/25px Noto Sans CJK KR" }}>Booking:</p>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins" }}>Enbill users can book their products online and offline.The
                        desired product can be booked 24*7 according to them. Add
                        products to their cart and book the product.</p>


                </div>

            </div>
            <div className="gradiantbar" style={{ height: "30px", width: "100%", opacity: "39%" }}>

            </div>
            <div className="row" style={{ padding: "3% 5%",width:"100%" }}>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo1.png")} />
                    </div>
                    <p style={{ color: "#EE7A37", font: "normal normal bold 17px/25px Noto Sans CJK KR" }}>Favourite store:</p>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins" }}>You can quickly add a store to your favourites list as a
                        user.This feature will help you to have easier access to your
                        favourite shops .</p>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo2.png")} />
                    </div>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div  ref={container4}>
                     
                    </div>

                </div>

            </div>

            <Footer></Footer>

        </>
    )
}

export default EnbillUserPage;