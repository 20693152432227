import Header from "../components/Haeder";
import Footer from "../components/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Career.css';
const AboutUs = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }
    return (
        <>
            <Header></Header>
            <div className="row" style={{ padding: "5%", backgroundColor: "#F5F5F5", width: "100%" }}>

                <div className="col-lg-7" style={{ padding: "5%", margin: "auto", display: "flex", flexDirection: "column", justifyContent: "baseline", textAlign: "left" }}>
                    <p style={{ font: "normal normal 600 48px/60px Source Serif Pro", fontSize: "48px" }}>“The value of an idea lies in the using of it.”-Thomas Edison</p>
                    <p className="mt-4" style={{ maxWidth: "500px", color: "#172026", textAlign: "left" }}>The dire need of Indian merchants to digitally connect with the
                        youth of India is the reason behind the invention of Enbill. a
                        “super-app” working tirelessly to eradicate every loophole
                        between merchant and customer. Enbill provides services like
                        digital Udhaar, billing, bookkeeping, and promotions, allowing
                        small merchants to be on the same playing field as their online
                        counterparts</p>
                    <button onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant", "_blank")} className="mt-4 btns" style={{ backgroundColor: "#EE7A37", color: "white", height: "40px", width: "168px", borderRadius: "28px", border: "none" }} >DOWNLOAD NOW</button>
                </div>
                <div className="col-lg-5">
                    <div style={{ position: "relative", marginLeft: "25%", height: "350px", width: "300px", borderTop: "4px solid white", borderLeft: "4px solid white", borderBottom: "4px solid #FE5858", borderRight: "4px solid #FE5858" }}>
                        <div style={{ position: "absolute", backgroundColor: "white", border: "6px solid #FE585840", borderRadius: "16px", padding: "5%", right: "-10%", top: "-10%", boxShadow: "0px 31px 26px #00000029" }}>
                            <img className="img-fluid" style={{}} src={require("../Assets/Images/aboutus1.png")} />
                        </div>
                        <div style={{ position: "absolute", backgroundColor: "white", border: "6px solid #FE585840", borderRadius: "16px", padding: "5%", left: "-20%", bottom: "-10%", boxShadow: "0px 31px 26px #00000029" ,marginBottom:"10px"}}>
                            <img className="img-fluid" style={{}} src={require("../Assets/Images/aboutus2.png")} />
                        </div>
                    </div>

                </div>



            </div>
            <div className="row who_are_we_box" style={{ display: "flex", justifyContent: "center", textAlign: "center", padding: "9%", backgroundColor: "#F5F5F5", marginTop: "5px !important", position: "relative", width: "100%" }}>
                <div className="row" style={{display:"flex",justifyContent:"center"}}>
                    <div className="box col" style={{ boxShadow: "0px 3px 6px #00000029", backgroundColor: "#EE7A37", borderRadius: "5px", textAlign: "center", maxWidth: "20%", minWidth: "200px", padding: "2%", height: "150px" ,display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
                        <p style={{ font: "normal normal bold 24px/36px Montserrat", color: "white" }}>Who we are</p>
                        <p style={{ font: "normal normal medium 16px/30px Montserrat", color: "white", fontSize: "16px" }}>Apna Bharat Apni Dukaan</p>

                    </div>
                </div>

                <div className="col" style={{ marginTop: "10%" }}>
                    <p style={{ color: "#11884F", font: "normal normal bold 36px/43px Montserrat" }}>About Us</p>
                    <p style={{ color: "#9E9E9E", font: "normal normal normal 18px/30px Karla" }}>
                        Company Page- About US
                        “The value of an idea lies in the using of it.”-Thomas Edison
                        The dire need of Indian merchants to digitally connect with the
                        youth of India is the reason behind the invention of Enbill. a
                        “super-app” working tirelessly to eradicate every loophole
                        between merchant and customer. Enbill provides services like
                        digital Udhaar, billing, bookkeeping, and promotions, allowing
                        small merchants to be on the same playing field as their online
                        counterparts.
                        About Us
                        Enbill is a retail start-up, focusing not only on supporting
                        merchants to organise their businesses financially but also
                        reconnecting them with their local communities. With a
                        vision to digitally connect the small merchants of India to the
                        fast-paced youth of the country, Enbill allows eradication of
                        the hassle of Udhaar tracking, payment reconciliations,
                        handwritten accounts, poor word-of-mouth promotions, and
                        much more. While the app is a definite boon for the
                        merchant, it is a blessing in disguise for customers too, in
                        search of local products or those in a crunch of time. EnBill
                        and its many features have a socially obligated vision to
                        bring local Grahaks back to local Dukan.
                    </p>

                </div>

            </div>
            <div className="row" style={{ width: "100%" }}>
                <div className="col">
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <p style={{ color: "#160637", font: "normal normal medium 29px/31px Poppins", fontSize: "29px",marginRight:"5px" }}>Core</p>
                        
                        <p style={{ color: "#EE7A37", font: "normal normal medium 29px/31px Poppins", fontSize: "29px" }}>Team</p>
                    </div>
                    <div className="col">
                        <div className="gd-carousel-wrapper">
                            <Carousel
                                // swipeable={false}
                                // draggable={false}
                                // showDots={true}
                                // responsive={responsive}
                                // ssr={true} // means to render carousel on server-side.
                                // infinite={true}

                                // autoPlaySpeed={1000}
                                // keyBoardControl={true}
                                // customTransition="all .5"
                                // transitionDuration={500}
                                // containerClass="carousel-container"

                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                                swipeable={true}
                                draggable={true}
                                autoPlay={false}
                                showDots={false}
                                ssr={true}
                                renderDotsOutside={true}
                                arrows={true}

                                responsive={responsive}
                            >

                                <div className="col" style={{
                                    border: "1px dashed #EE7A37", boxShadow: "0px 28px 62px #00000026", margin: "50px ",
                                    //  maxWidth: "250px",
                                    minWidth: "200px"
                                }}>

                                    <div className="cardtop" >
                                        <div style={{ display: "flex", justifyContent: "center", padding: "10%" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%" }} src={require("../Assets/Images/partnerimg1.png")} />

                                        </div>

                                    </div>
                                    <div className="desc" style={{ textAlign: "center", padding: "10%" }}>
                                        <p>Rachna Khetan</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins" }}>She has rich experience of 25+ years in the fields of
                                            business and as advisory role to the top management. An
                                            Expert in creating value through financial engineering and
                                            restructuring.</p>
                                        <p style={{ color: "#EE7A37", font: "normal normal medium 14px/35px Poppins" }}>Read More</p>
                                    </div>
                                </div>

                                <div className="col" style={{
                                    border: "1px dashed #EE7A37", boxShadow: "0px 28px 62px #00000026", margin: "50px ",
                                    //  maxWidth: "250px",
                                    minWidth: "200px"
                                }}>
                                    <div className="cardtop" >
                                        <div style={{ display: "flex", justifyContent: "center", padding: "10%" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%" }} src={require("../Assets/Images/partnerimg2.png")} />

                                        </div>

                                    </div>
                                    <div className="desc" style={{ textAlign: "center", padding: "10%" }}>
                                        <p>Rakesh Kumar Raizada</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins" }}>He has rich experience of 30+ years in the field of sales and
                                            marketing, has mentored various projects in retail and ed
                                            tech. While working in Europe (1998–2001) and India, he
                                            developed his expertise in sales, marketing, project
                                            handling, Salesforce, and other SaaS-based products.</p>
                                        <p style={{ color: "#EE7A37", font: "normal normal medium 14px/35px Poppins" }}>Read More</p>
                                    </div>
                                </div>
                                <div className="col" style={{
                                    border: "1px dashed #EE7A37", boxShadow: "0px 28px 62px #00000026", margin: "50px ",
                                    //  maxWidth: "250px", 
                                    minWidth: "200px"
                                }}>
                                    <div className="cardtop" >
                                        <div style={{ display: "flex", justifyContent: "center", padding: "10%" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%" }} src={require("../Assets/Images/partnerimg3.png")} />

                                        </div>

                                    </div>
                                    <div className="desc" style={{ textAlign: "center", padding: "10%" }}>
                                        <p>Pankaj Khetan</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins" }}>He has rich experience of 30+ years in the field of all types
                                            of finance and taxation. An Insolvency professional with
                                            hands-on experience in debt restructuring and business
                                            takeovers.</p>
                                        <p style={{ color: "#EE7A37", font: "normal normal medium 14px/35px Poppins" }}>Read More</p>
                                    </div>
                                </div>
                                <div className="col" style={{
                                    border: "1px dashed #EE7A37", boxShadow: "0px 28px 62px #00000026", margin: "50px ",
                                    //  maxWidth: "250px", 
                                    minWidth: "200px"
                                }}>
                                    <div className="cardtop" >
                                        <div style={{ display: "flex", justifyContent: "center", padding: "10%" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%" }} src={require("../Assets/Images/sir image3.png")} />

                                        </div>

                                    </div>
                                    <div className="desc" style={{ textAlign: "center", padding: "10%" }}>
                                        <p>Rishabh Raizada</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins" }}>
                                            Gaming bug bit him at age 6, in the form game called "RISE OF THE NATIONS's" which helped him to develop analytical thinking, problem solving, attention to detail, and leadership skills.
                                            His interest and passion for the game made him interested in "HOW IT WORKS's" which led him to learn the nuances of building software. By the age of 13, he was part of teams(double his age) developing software for various clients in India and abroad.He also worked and delivered various complex projects for companies as a leader of a team spread across various functions.
                                        </p>
                                        <p style={{ color: "#EE7A37", font: "normal normal medium 14px/35px Poppins" }}>Read More</p>
                                    </div>
                                </div>

                                {/* <div className="col">  <img className="img-fluid" style={{ marginLeft: "30%",marginRight: "30%"  }} src={require("../Assets/Images/aboutus2.png")} /></div>
                            <div className="col">  <img className="img-fluid" style={{ marginLeft: "30%",marginRight: "30%"  }} src={require("../Assets/Images/aboutus1.png")} /></div> */}

                            </Carousel>
                        </div>


                    </div>





                </div>

            </div>
            <div className="row" style={{ position: "relative", width: "100%" }}>
                <div className="col">
                    <div className="ms-5 ms-md-0" style={{ display: "flex", justifyContent: "center" }}>
                        <p style={{ fontSize: "60px", fontWeight: "600", color: "#000000" }}>Who We Are/Meet Our Team</p>
                    </div>
                    <div className="col">
                        <div className="gd-carousel-wrapper">
                            <Carousel
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                                swipeable={true}
                                draggable={true}
                                autoPlay={false}
                                showDots={false}
                                ssr={true}
                                renderDotsOutside={true}
                                arrows={true}

                                responsive={responsive}
                            >

                                <div className="col" style={{ border: "1px dashed #11884F", boxShadow: "0px 28px 62px #00000026", margin: "100px", maxWidth: "250px", minWidth: "200px" }}>

                                    <div className="cardtop" style={{ padding: "10%" }}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%" }} src={require("../Assets/Images/aboutusemp1.png")} />

                                        </div>

                                    </div>
                                    <div className="desc" style={{ textAlign: "center", padding: "2%" }}>
                                        <p style={{ color: "#51495F", fontSize: "12px" }}>Web Design Project</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins", fontSize: "8px" }}>UX/UI Design</p>
                                        <p style={{ color: "#540CFA", font: "normal normal medium 14px/35px Poppins", fontSize: "11px" }}>Learn More &gt;</p>
                                    </div>
                                </div>

                                <div className="col" style={{ border: "1px dashed #11884F", boxShadow: "0px 28px 62px #00000026", margin: "100px", maxWidth: "250px", minWidth: "200px" }}>

                                    <div className="cardtop" style={{ padding: "10%" }}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%" }} src={require("../Assets/Images/aboutusemp1.png")} />

                                        </div>

                                    </div>
                                    <div className="desc" style={{ textAlign: "center", padding: "2%" }}>
                                        <p style={{ color: "#51495F", fontSize: "12px" }}>Web Design Project</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins", fontSize: "8px" }}>UX/UI Design</p>
                                        <p style={{ color: "#540CFA", font: "normal normal medium 14px/35px Poppins", fontSize: "11px" }}>Learn More &gt;</p>
                                    </div>
                                </div>
                                <div className="col" style={{ border: "1px dashed #11884F", boxShadow: "0px 28px 62px #00000026", margin: "100px", maxWidth: "250px", minWidth: "200px" }}>

                                    <div className="cardtop" style={{ padding: "10%" }}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%" }} src={require("../Assets/Images/aboutusemp1.png")} />

                                        </div>

                                    </div>
                                    <div className="desc" style={{ textAlign: "center", padding: "2%" }}>
                                        <p style={{ color: "#51495F", fontSize: "12px" }}>Web Design Project</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins", fontSize: "8px" }}>UX/UI Design</p>
                                        <p style={{ color: "#540CFA", font: "normal normal medium 14px/35px Poppins", fontSize: "11px" }}>Learn More &gt;</p>
                                    </div>
                                </div>
                                <div className="col" style={{ border: "1px dashed #11884F", boxShadow: "0px 28px 62px #00000026", margin: "100px", maxWidth: "250px", minWidth: "200px" }}>

                                    <div className="cardtop" style={{ padding: "10%" }}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%" }} src={require("../Assets/Images/aboutusemp1.png")} />

                                        </div>

                                    </div>
                                    <div className="desc" style={{ textAlign: "center", padding: "2%" }}>
                                        <p style={{ color: "#51495F", fontSize: "12px" }}>Web Design Project</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins", fontSize: "8px" }}>UX/UI Design</p>
                                        <p style={{ color: "#540CFA", font: "normal normal medium 14px/35px Poppins", fontSize: "11px" }}>Learn More &gt;</p>
                                    </div>
                                </div>



                            </Carousel>



                        </div>


                    </div>





                </div>

            </div>
            <div className="row" style={{ marginBottom: "5%", width: "100%" }}>
                <div className="col" style={{ backgroundColor: "#EE7A37", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", padding: "1%" }}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                        <div>
                            <p style={{ color: "white", font: "normal normal bold 35px/68px Roboto" }}>Wanna be a part of Indotechs EnBill Pvt Ltd?</p>
                        </div>

                        <div>
                            <a href="/ContactUs" style={{border:"none",textDecoration:"none", marginTop: "-20px", borderRadius: "14px", padding: "5px 20px", color: "#EE7A37", backgroundColor: "white", font: "normal normal bold 30px/60px Roboto" ,display:"block "}}>Join</a>
                        </div>
                    </div>

                </div>



            </div>
            <Footer></Footer>

        </>
    )
}

export default AboutUs;