import Header from "../components/Haeder";
import Footer from "../components/Footer";
import React from "react";
import RangeSlider from 'react-bootstrap-range-slider';

const EnbillInvestorRelation=()=>{
    return(
        <>
        <Header></Header>
        <div className="row" style={{ padding: "5%", backgroundColor: "#F5F5F5",width:"100%" }}>
                <div className="col" style={{ textAlign: "center" }}>
                    <p style={{marginTop:"3%", color: "#EE7A37",font: "normal normal 600 50px/60px Source Serif Pro" ,fontSize:"20px"}}>OUR OFFICES</p>
                    <p className="mt-3" style={{font: "normal normal 600 50px/60px Source Serif Pro"}}>Visit us Anytime</p>
                    <div style={{}}>
                      
                        <p style={{font: "normal normal 600 50px/60px Source Serif Pro",fontSize:"35px"}}> Come and visit our quarters or simply send us an email anytime you want.</p>
                    </div>


                </div>
            </div>
            <div className="row" style={{width:"100%"}}>
                <div className="col-md-7" style={{padding:"5%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                    <div style={{display:"flex",alignItems:"baseline"}}>
                         <div style={{height:"20px",width:"20px", backgroundColor:"orange",borderRadius:"50%",marginRight:"32px" }}></div>
                         <div>  <p style={{font: "normal normal medium 30px/51px Poppins",fontSize:"30px"}}>Always on Support</p></div>  
                
                    </div>
                    <div style={{backgroundColor:"black",opacity:"25%",width:"50%",height:"3px"}}></div>
                    <div>
                        <p style={{color:"#8989A2",fontSize:"22px",marginTop:"20px"}}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et</p>
                    </div>
                 

                </div>
                <div className="col-md-5">
               
                 <img style={{}} className="img-fluid" src={require("../Assets/Images/invrel2.png")} />
                </div>

            </div>
            <div className="row" style={{width:"100%"}}>
                
                <div className="col-md-5" style={{paddingLeft:"50px"}}>
               
                 <img style={{}} className="img-fluid" src={require("../Assets/Images/invrel2.png")} />
                </div>
                <div className="col-md-7" style={{padding:"5%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                    <div style={{display:"flex",alignItems:"baseline"}}>
                         <div style={{height:"20px",width:"20px", backgroundColor:"orange",borderRadius:"50%",marginRight:"32px" }}></div>
                         <div>  <p style={{font: "normal normal medium 30px/51px Poppins",fontSize:"30px"}}>Always on Support</p></div>  
                
                    </div>
                    <div style={{backgroundColor:"black",opacity:"25%",width:"50%",height:"3px"}}></div>
                    <div>
                        <p style={{color:"#8989A2",fontSize:"22px",marginTop:"20px"}}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et</p>
                    </div>
                 

                </div>

            </div>
            <div className="row" style={{width:"100%"}}>
                <div className="col-md-7" style={{padding:"5%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                    <div style={{display:"flex",alignItems:"baseline"}}>
                         <div style={{height:"20px",width:"20px", backgroundColor:"orange",borderRadius:"50%",marginRight:"32px" }}></div>
                         <div>  <p style={{font: "normal normal medium 30px/51px Poppins",fontSize:"30px"}}>Always on Support</p></div>  
                
                    </div>
                    <div style={{backgroundColor:"black",opacity:"25%",width:"50%",height:"3px"}}></div>
                    <div>
                        <p style={{color:"#8989A2",fontSize:"22px",marginTop:"20px"}}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et</p>
                    </div>
                 

                </div>
                <div className="col-md-5">
               
                 <img style={{}} className="img-fluid" src={require("../Assets/Images/invrel2.png")} />
                </div>

            </div>
        <Footer></Footer>
        </>
    )
}

export default EnbillInvestorRelation;