import Footer from "../components/Footer";
import Header from "../components/Haeder";
import FooterLandingPage from "../components/FooterLandingPage";
import React from 'react'
// import Lottie from 'react-lottie-player'
import Lottie from "lottie-web";
import { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { useState } from "react";

const EnbillLandingPage = () => {
    const container = useRef(null);
    const[video1,setVideo1]=useState(false)
    const[video2,setVideo2]=useState(false)
    const[video3,setVideo3]=useState(false)
    const[video4,setVideo4]=useState(false)
    const[video5,setVideo5]=useState(false)

    if(video1===true){
      var url = "https://www.youtube.com/watch?v=TN9hYfcCIpQ"
    }else if(video2===true){
        var url = "https://www.youtube.com/watch?v=Lcfos5IOrc4"
    }else if(video3===true){
        var url = "https://www.youtube.com/watch?v=hppF7tv7iVg"
    }else if(video4 === true){
        var url = "https://www.youtube.com/watch?v=ziuaA5EegG0"
    }else if(video5 === true){
       var url ="https://www.youtube.com/watch?v=6Agr4uUYMvk"
    }


    const [state, setState] = useState({
        playing: true
    })
    const { playing } = state
    useEffect(() => {
        Lottie.loadAnimation({
            container: container.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: require("../Assets/Images/animation1.json")
        });
        return () => {
            Lottie.destroy();
        };
    }, [])

    return (
        <>
            <Header></Header>
            <div className="row" style={{ width: "100%", padding: "5%", backgroundColor: "#F5F5F5", width: "100%", margin: "0" }}>

                <div className="col-md-6" style={{ padding: "5%", margin: "auto", display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: 'baseline' }}>

                    <h4 style={{ fontSize: "1.8rem", textAlign: "left" }}>Local Dukaan <br></br>Local Grahak</h4>
                    <p className="mt-4" style={{ width: "300px", textAlign: "left" }}>Communicate and Promote with your customers directly</p>
                    <a href="https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant" target="_blank" className="mt-4 btns" style={{ backgroundColor: "#EE7A37", color: "#fff", textDecoration: "none", padding: "8px 25px", borderRadius: "28px", border: "none", border: "1px solid #EE7A37 " }} >DOWNLOAD NOW</a>

                </div>

                <div className="col-md-6">
                    <img className="img-fluid" style={{}} src={require("../Assets/Images/newphones.png")} />
                </div>


              
            </div>
            <div className="row" style={{ width: "100%" }}>
                <div className="col" style={{ padding:"1%" }}>

                     
                    <img  onClick={()=>{setVideo1(true);setVideo2(false);setVideo3(false);setVideo4(false);setVideo5(false)}} className="img-fluid videos ytvideos" data-toggle="modal" data-target="#exampleModal" style={{margin:"0",height:"237px",width:"316px",borderRadius:"36px",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} src={require("../Assets/Images/videologo1.jpg")} />
                    <img  onClick={()=>{setVideo1(false);setVideo2(true);setVideo3(false);setVideo4(false);setVideo5(false)}} className="img-fluid videos ytvideos" data-toggle="modal" data-target="#exampleModal" style={{marginLeft:"-3%", padding:"0",height:"237px",width:"316px",borderRadius:"36px",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} src={require("../Assets/Images/manageudhaar.jpg")} />
                    <img  onClick={()=>{setVideo1(false);setVideo2(false);setVideo3(true);setVideo4(false);setVideo5(false)}}  className="img-fluid videos ytvideos" data-toggle="modal" data-target="#exampleModal" style={{marginLeft:"-3%", padding:"0",position:"relative",zIndex:"9",height:"237px",width:"316px",borderRadius:"36px",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} src={require("../Assets/Images/logobooking.jpg")} />
                    <img  onClick={()=>{setVideo1(false);setVideo2(false);setVideo3(false);setVideo4(true);setVideo5(false)}} className="img-fluid videos ytvideos" data-toggle="modal" data-target="#exampleModal" style={{marginLeft:"-3%", padding:"0",height:"237px",width:"316px",borderRadius:"36px",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} src={require("../Assets/Images/qrcodevideo.jpg")} />
                    <img  onClick={()=>{setVideo1(false);setVideo2(false);setVideo3(false);setVideo4(false);setVideo5(true)}}  className="img-fluid videos ytvideos" data-toggle="modal" data-target="#exampleModal" style={{marginLeft:"-3%", padding:"0",height:"237px",width:"316px",borderRadius:"36px",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} src={require("../Assets/Images/qrvideo.jpg")} />
                </div>

            </div>
            <div className="row archieves-main" style={{ paddingLeft: "8%", paddingRight: "8%", width: "100%", margin: "0" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <p style={{ color: "#14183E", fontSize: "50px", fontWeight: "600" }}>Archives</p>
                </div>

                <div className="col-md archieves" style={{ position: "relative", padding: "3%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", textAlign: "center", height: "270px" }}>
                    <div>
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/greencheck1.png")} />
                    </div>

                    <p style={{ color: "#1E1D4C", fontSize: "20px", paddingTop: "5%" }}>11 August 2021</p>
                    <p className="" style={{ color: "#1E1D4C", fontSize: "14px" }}>Indotechs Software Pvt, Ltd. was founded</p>
                </div>

                <div className="col-md archieves" style={{ padding: "3%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", textAlign: "center", height: "270px" }}>
                    <div>
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/greencheck2.png")} />
                    </div>

                    <p style={{ color: "#1E1D4C", fontSize: "20px", paddingTop: "5%" }}>22 Feb 2022</p>
                    <p className="" style={{ color: "#1E1D4C", fontSize: "14px" }}>Launched MERCHANT & CUSTOMER app</p>
                </div>
                <div className="col-md archieves" style={{ padding: "3%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", textAlign: "center", height: "270px" }}>
                    <div>
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/greencheck3.png")} />
                    </div>

                    <p style={{ color: "#1E1D4C", fontSize: "20px", paddingTop: "5%" }}>April 2022</p>
                    <p className="" style={{ color: "#1E1D4C", fontSize: "14px" }}>reached 1000 merchants</p>
                </div>
                <div className="col-md archieves" style={{ padding: "3%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", textAlign: "center", height: "270px" }}>

                    <div>
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/greencheck4.png")} />
                    </div>
                    <p style={{ color: "#1E1D4C", fontSize: "20px", paddingTop: "5%" }}>August 2022</p>
                    <p className="" style={{ color: "#1E1D4C", fontSize: "14px" }}>Web MERCHANT was Launched</p>
                </div>

            </div>
            <div className="row" style={{ padding: "1% 3%", width: "100%", margin: "0" }}>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ textAlign: "left", padding: "5%" }}>
                        <p style={{ color: "#EE7A37", font: "normal normal bold 30px/40px Segoe UI" }}>Our Vision</p>
                        <p style={{ color: "#5E6282", font: "normal normal normal 22px Segoe UI", }}>Engage millions of traditional small retailers for not only
                            financial inclusion but also to build their durable financial
                            strengths.
                            Lead the social cause of empowering these small offline
                            shopkeepers and recirculate the revenue back where it belongs.</p>
                    </div>

                </div>
                <div className="col-md">
                    <div>
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/landinglogo1.png")} />
                    </div>

                </div>

            </div>
            <div className="row" style={{ padding: "1% 3%", width: "100%", margin: "0" }}>
                <div className="col-md">
                    <div>
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/2.png")} />
                    </div>


                </div>
                <div className="col-md" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ textAlign: "start", padding: "5%" }}>
                        <p style={{ color: "#EE7A37", font: "normal normal bold 30px/40px Segoe UI" }}>Convenience</p>
                        <p style={{ color: "#5E6282", font: "normal normal normal 22px Segoe UI" }}>EnBill helps consumers by fulfilling their instant needs. Locate
                            stores within a 15 km radius, as well as their online catalogue, and
                            make purchases at these offline locations This hyper-local product/
                            catalogue allows shopkeepers to reconnect with their customers,
                            thus increasing their visibility to the public.</p>
                    </div>

                </div>

            </div>
            <div className="row" style={{ backgroundColor: "#F5F5F5", width: "100%", marginTop: "2%", margin: "0" }}>
                <div style={{ display: "flex", justifyContent: "center", fontWeight: "500" }}>
                    <p style={{ color: "#EE7A37", fontSize: "19px", marginTop: "10px" }}>Accepting Payments Made Easy, Simple & FREE!</p>
                </div>

                <div className="col-md" style={{ textAlign: "center", marginTop: "-1%" }}>
                    <div>
                        <img className="img-fluid" style={{ padding: "2%" }} src={require("../Assets/Images/paytm.png")} />
                        <img className="img-fluid" style={{ padding: "2%" }} src={require("../Assets/Images/googlepay.png")} />
                        <img className="img-fluid" style={{ padding: "2%" }} src={require("../Assets/Images/phonepay.png")} />
                        <img className="img-fluid" style={{ padding: "2%" }} src={require("../Assets/Images/paypal.png")} />
                        <img className="img-fluid" style={{ padding: "2%" }} src={require("../Assets/Images/upi.png")} />
                        <img className="img-fluid" style={{ padding: "2%" }} src={require("../Assets/Images/billpay.png")} />
                        <img className="img-fluid" style={{ padding: "2%" }} src={require("../Assets/Images/wa.png")} />
                        <img className="img-fluid" style={{ padding: "2%" }} src={require("../Assets/Images/fasttag.png")} />
                        <img className="img-fluid" style={{ padding: "2%" }} src={require("../Assets/Images/jiomoney.png")} />
                        <img className="img-fluid" style={{ padding: "2%" }} src={require("../Assets/Images/rupay.png")} />
                        <img className="img-fluid" style={{ padding: "2%" }} src={require("../Assets/Images/mobikwik.png")} />
                    </div>

                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "-1%" }}>
                    <p style={{ fontSize: "6px", color: "#11884F" }}>The logos above are the property of respective trademark owners. All the above apps support BHIM-UPI.</p>
                </div>

            </div>
            <div className="row" style={{ padding: "1% 3%", marginTop: "3%", width: "100%", margin: "0" }}>
                <div className="col-md">
                    <div>
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/landinglogo2.png")} />
                    </div>


                </div>

                <div className="col-md" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ textAlign: "left", padding: "5%" }}>
                        <p style={{ color: "#EE7A37", font: "normal normal bold 30px/40px Segoe UI" }}>Promoting MSME’s</p>
                        <p style={{ color: "#5E6282", font: "normal normal normal 22px Segoe UI" }}>With recent government pushes towards digitalisation ,MSME’s
                            (Micro, Small, and Medium Enterprises) are looking to go digital.
                            To become a part of the digital world, all one needs to do is
                            download the EnBill App.</p>

                    </div>

                </div>



            </div>
            <div className="row" style={{ padding: "1% 3%", width: "100%", margin: "0" }}>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <div style={{ textAlign: "left", padding: "5%" }}>
                        <p style={{ color: "#EE7A37", font: "normal normal bold 30px/40px Segoe UI" }}>For Every</p>
                        <p style={{ color: "#7A7878", font: "normal normal normal 22px/33px Poppins" }}>Business</p>
                        <div className="arrpara1" style={{ display: "flex" }}>
                            <p onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant", "_blank")} style={{ minWidth: "300px", color: "#7A7878", font: "normal normal normal 16px/21px Segoe UI", position: "relative" }}>I Want To  <span style={{ color: "#EE7A37" }}>Become a Enbill Merchnat</span></p>

                            <span onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant", "_blank")} className="arrow" style={{ fontSize: "35px", marginTop: "-5%" }}>&gt;</span>

                        </div>


                        <p style={{ color: "#7A7878", font: "normal normal normal 10px/14px Segoe UI" }}>Know More</p>

                        <div style={{ height: "1px", width: "60%", backgroundColor: "#BEBEBE" }}></div>

                        <div className="arrpara2" style={{ display: "flex", marginTop: "10px" }}>
                            <p onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.indotechsj.userapp", "_blank")} style={{ minWidth: "300px", color: "#7A7878", font: "normal normal normal 16px/21px Segoe UI", position: "relative" }}>I Want To  <span style={{ color: "#EE7A37" }}>Become a Enbill User</span></p>

                            <span onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.indotechsj.userapp", "_blank")} className="arrow2" style={{ fontSize: "35px", marginTop: "-5%" }}>&gt;</span>

                        </div>

                        <p style={{ color: "#7A7878", font: "normal normal normal 10px/14px Segoe UI" }}>Know More</p>

                        <div style={{ height: "2px", width: "60%", backgroundColor: "#BEBEBE" }}></div>

                    </div>

                </div>
                <div className="col-md">
                    <div>
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/landinglogo3.png")} />
                    </div>


                </div>

            </div>
            <div className="row" style={{ padding: "1% 3%", width: "100%", margin: "0" }}>
                <div className="col-md container" ref={container}>
             


                </div>
                <div className="col-md" style={{ padding: "2%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <p style={{ color: "#EE7A37", font: "normal normal bold 28px/42px Poppins" }}> Connecting India Digitally</p>
               
                    <p style={{ textAlign: "left", color: "#5E6282", font: "normal normal normal 22px Segoe UI" }}>India is one of the fastest growing fintech markets in the world. It
                        is not easy for every business to go digital, especially in an
                        economy dominated by MSME's like India's. EnBill provides a
                        chance for these MSMEs to cross the fence of digital adoption by
                        providing them a platform for their everyday trading.</p>

                </div>

            </div>
          
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content res_video">

                        <div class="modal-body" style={{ height: "500px", padding: "0"}}>
                      
                            <ReactPlayer url={url} width="100%" height="100%" playing controls></ReactPlayer>

                        </div>

                    </div>
                </div>
            </div>

       



            <FooterLandingPage></FooterLandingPage>

        </>
    )
}

export default EnbillLandingPage;