const Footer=()=>{
  
    return(
        <>
        <div className="row" style={{width:"100%"}}>
                <div className="col" style={{ padding: "3%" }}>
                   
                        
                            {/* <div style={{ backgroundColor: "#002147", borderRadius: "12px 12px 0 0", padding: "2% 5%" }}>
                                <div className="row" style={{width:"100%"}}>
                                    <div className="col-lg"

                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div>
                                            <img style={{}} src={require("../Assets/Images/report.png")} />

                                        </div>

                                    </div>
                                    <div className="col-lg-5  " style={{ marginRight: "3%" }}>
                                        <p style={{ color: "white", fontSize: "19px" }}>  ON YOUR MOBILE APP <br></br>Registered EnBill users can report suspicious activity on the EnBill app Go to the EnBill app on your mobile Go to :Help &gt; Account security issue-Report Fraudulent activity  &lt;Screenshot &gt;</p>
                                    </div>
                                    <div className="col-lg-4 mb-px-0 footer" style={{ marginRight: "2%"}}>
                                        <p style={{ color: "white", fontSize: "19px" }}>ON THE WEBSITE <br></br> Not registered on Enbill? You can still report an issue! Go to <br></br><p className="mt-4">support.Enbill.com</p></p>

                                    </div>
                                    <div className="col-lg " style={{ display: "flex", justifyContent: "center" }}>
                                        <p style={{ color: "white" }}>Report complaints to the nearest cyber crime center or online at <span style={{ color: "#EE7A37" }}>https://cybercrime.gov.in/</span></p>
                                    </div>
                                </div>

                            </div>
                            <div style={{backgroundColor:"#11884F",height:"25px",borderRadius:"0 0 31px 31px"}}></div> */}
                            <div style={{ backgroundColor: "#EE7A37", borderRadius: "12px", padding: "2% 5%" ,boxShadow:"0px 3px 6px #00000029"}}>
                                <div className="row" style={{width:"100%"}}>
                                    <div className="col-lg"

                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div>
                                            <img style={{}} src={require("../Assets/Images/report.png")} />

                                        </div>

                                    </div>
                                    <div className="col-lg-5  " style={{ marginRight: "3%" }}>
                                        <p style={{ color: "white", fontSize: "19px",textAlign:"left",font: "normal normal normal 19px/24px Roboto" }}>  ON YOUR MOBILE APP <br></br>Registered EnBill users can report suspicious activity on the EnBill app Go to the EnBill app on your mobile Go to :Help &gt; Account security issue-Report Fraudulent activity  &lt;Screenshot &gt;</p>
                                    </div>
                                    <div className="col-lg-4 mb-px-0 footer" style={{ marginRight: "2%"}}>
                                        <p style={{ color: "white", fontSize: "19px" ,textAlign:"left",font: "normal normal normal 19px/24px Roboto"}}>ON THE WEBSITE <br></br> Not registered on Enbill? You can still report an issue! Go to <br></br><p className="mt-4" style={{color:"#11884F"}}>support.Enbill.com</p></p>

                                    </div>
                                    <div className="col-lg " style={{ display: "flex", justifyContent: "center" }}>
                                        <p className="ms-lg-5 " style={{ color: "white", textAlign:"left",fontSize:"19px",font: "normal normal normal 19px/24px Roboto"}}>Report complaints to the nearest cyber crime center or online at <span style={{ color: "#161616" }}>https://cybercrime.gov.in/</span></p>
                                    </div>
                                </div>

                            </div>
                         
                    
                  



                </div>


            </div>
            <div className="row" style={{ padding: "5%", backgroundColor: "#F5F5F5", boxShadow: "rgba(0, 0, 0, 0.3) 0px 4px 9px", width: "100%" }}>
                <div className="col" style={{ marginLeft: "5%" }}>
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>Account</p>
                    <p  onClick={()=> window.open("http://merchant.enbill.in/", "_blank")} style={{cursor:"pointer", fontSize: "16px", color: "#545D63" }}>Sign In</p>
                    <p style={{ fontSize: "16px", color: "#545D63" }}>Register</p>
                </div>
                <div className="col-5">
                    <a href="/AboutUs" style={{ fontWeight: "600", fontSize: "16px",textDecoration:"none",color:"#172026" }}>Our Story </a>

                    <a href="/EnbillCareerPage" style={{display:"block",textDecoration:"none", fontSize: "16px", color: "#545D63",marginTop:"15px"}}>Careers</a>
                </div>
                <div className="col social_media" style={{display:"flex" ,alignItems:"center",flexDirection:"column"}}>
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>See All Apps</p>
                    <a href="https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant" target="_blank" style={{ color: "#EE7A37", border: "1px solid #11884F", backgroundColor: "white", borderRadius: "11px", padding: "5px 10px",display:"block",width:"150px"}}>Download Now</a>
                    <div style={{ paddingTop: "25px" }}>
                        <a href="https://www.instagram.com/enbillbharat/" target="_blank" className="mt-4 btns">             <img style={{ padding: "5px" }} src={require("../Assets/Images/instagram.png")} /></a>
                        <a href="https://www.facebook.com/EnBillBharat" target="_blank" className="mt-4 btns">   <img style={{ padding: "5px" }} src={require("../Assets/Images/facebook.png")} /> </a>
                      
                        <a href="https://twitter.com/Enbillbharat" target="_blank" className="mt-4 btns">  <img style={{ padding: "5px" }} src={require("../Assets/Images/twitter.png")} /></a>
                    </div>

                </div>
            </div>

            <div className="row" style={{ padding: "1% 5%",width:"100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", }}>
                        <img className="img-fluid" src={require("../Assets/Images/logoenbil.png")} style={{height:"50px",marginTop:"5px"}}/>
                        <div>
                               <p style={{ marginTop: "15px" }}>EnBill</p>
                        </div>
                     <div style={{marginRight:"60px"}}>
                         <p style={{ marginTop: "20px", marginLeft: "5px",width:"100%",color:"#C3CCD3",font:"normal normal medium Raleway" ,fontSize:"12px"}}>© Copyright@EnBill-2022-AllRights Reserved.</p>
                     </div>
                       
                    </div>
                    <div style={{ display: "flex", paddingRight: "9%" }}>
                        <a href="" style={{ marginTop: "15px", color: "#172026" }}>Terms of Use</a>
                        <a href="" style={{ marginTop: "15px", marginLeft: "30px", color: "#172026" }}>Privacy Policy</a>

                    </div>

                </div>
            </div>
        </>
    )
}
export default Footer;