import Header from "../components/Haeder";
import Footer from "../components/Footer";
import '../components/Component.css';
import { useState } from "react";

const ContactUs = () => {
    const [isActive, setIsActive] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const [isActive3, setIsActive3] = useState(false);
    const [isActive4, setIsActive4] = useState(false);
    const handleClick = event => {

        setIsActive(current => !current);
    };
    const handleClick2 = event => {

        setIsActive2(current => !current);
    };
    const handleClick3 = event => {

        setIsActive3(current => !current);
    };
    const handleClick4 = event => {

        setIsActive4(current => !current);
    };

    const [name, setName] = useState(null);
    const getName = (val) => {
        setName(val.target.value)

    }



    const [phonenumber, setPhonenumber] = useState(null);
    const getPhonenumber = (val) => {
        setPhonenumber(val.target.value)

    }


    const [message, setMessage] = useState(null);
    const getMessage = (val) => {
        setMessage(val.target.value)

    }

    const [email, setEmail] = useState(null);
    const getEmail = (val) => {
        setEmail(val.target.value)

    }
    const [required, setRequired] = useState(false);



    const mail = () => {
        var ebodycontact = 'Name:%20' + name + '%0D%0A' + 'Phone Number:%20' + phonenumber + '%0D%0A' + 'Email:%20' + email + '%0D%0A' + 'Message:%20' + message;
        if (name === null) {
            alert("please enter your name")
        } else if (phonenumber === null) {
            alert("please enter your number")
        } else if (email === null) { alert("please enter your email") } else {


            window.location.href = "mailto: hello@enbill.in?body=" +
                ebodycontact
        }
    }
    return (
        <>
            <Header></Header>
            <div className="row" style={{ backgroundColor: "#F5F5F5", paddingBottom: "20px", width: "100%" }}>

                <div className="col-lg-6" style={{ marginTop: "5%", margin: "auto" }}>
                    <div style={{ marginTop: "5%", paddingLeft: "20%", display: "flex", flexDirection: "column", textAlign: 'left' }}>

                        <p className="sample" style={{ font: "normal normal 600 48px/60px Source Serif Pro" }}>Help & Support</p>
                        <p style={{ marginTop: "1px", fontSize: "16px", font: "normal normal normal 16px/24px Muli" }}>Got a question about the EnBill app or wish to reach out to us for anything else?</p>
                        <div style={{ marginTop: "10px", border: "1px solid #EE7A37", borderRadius: "12px", backgroundColor: "#F7ECE8", display: "flex", maxWidth: "60%", padding: "2%", justifyContent: "center", height: "73px" }}>
                            <div style={{ height: "50px", width: "50px", borderRadius: "50%", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div>
                                    <img className="img-fluid" style={{ height: "30px" }} src={require("../Assets/Images/customersupport.png")} />
                                </div>

                            </div>
                            <div style={{ marginLeft: "5%" }}>
                                <p style={{ color: "#EE7A37", font: "normal normal bold 9px/10px Muli", marginTop: "2px" }}>CUSTOMER SUPPORT</p>
                                <p style={{ color: "#EE7A37", font: "normal normal bold 9px/10px Muli" }}>OUR HELPLINE NUMBER:<span style={{ color: "#11884F" }}> 0120 4321739</span> </p>

                            </div>

                        </div>
                        <div style={{ marginTop: "10px", border: "1px solid #EE7A37", borderRadius: "12px", backgroundColor: "#F7ECE8", display: "flex", maxWidth: "60%", padding: "2%", justifyContent: "center", height: "73px" }}>
                            <div style={{ height: "50px", width: "50px", borderRadius: "50%", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div>
                                    <img className="img-fluid" style={{ height: "30px" }} src={require("../Assets/Images/customersupport.png")} />
                                </div>

                            </div>
                            <div style={{ marginLeft: "5%" }}>
                                <p style={{ color: "#11884F", font: "normal normal bold 9px/10px Muli", marginTop: "2px" }}>MERCHANT SUPPORT</p>
                                <p style={{ color: "#EE7A37", font: "normal normal bold 9px/10px Muli" }}>OUR HELPLINE NUMBER:<span style={{ color: "#11884F" }}> 0120 4321739</span> </p>

                            </div>

                        </div>
                        <div style={{ marginTop: "10px", border: "1px solid #EE7A37", borderRadius: "12px", backgroundColor: "#F7ECE8", display: "flex", maxWidth: "60%", padding: "2%", justifyContent: "center", height: "73px" }}>
                            <div style={{ height: "50px", width: "50px", borderRadius: "50%", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div>
                                    <img className="img-fluid" style={{}} src={require("../Assets/Images/error.png")} />
                                </div>

                            </div>
                            <div style={{ marginLeft: "5%" }}>
                                <p style={{ color: "#D91E09", font: "normal normal bold 9px/10px Muli", marginTop: "2px" }}>REPORT FRAUD</p>
                                <p style={{ color: "#EE7A37", font: "normal normal bold 9px/10px Muli" }}>OUR HELPLINE NUMBER:<span style={{ color: "#11884F" }}> 0120 4321739</span> </p>

                            </div>

                        </div>
                        {/* <div>
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/aboutusimg.png")} />
                        <div style={{marginTop:"10px",marginBottom:"10px"}}>
                               <img className="img-fluid" style={{}} src={require("../Assets/Images/aboutusimg1.png")} />
                        </div>
                     

                        </div> */}


                    </div>
                </div>
                <div className="col-lg-6" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img className="img-fluid" style={{ marginTop: "90px" }} src={require("../Assets/Images/contactus1.png")} />


                </div>

            </div>
            <div className="row" style={{ padding: "3%", paddingLeft: "5%", width: "100%" }}>
                <div className="col" style={{}}>
                    <div className="row" style={{ width: "100%" }}>
                        <div className="col-md-3" style={{ textAlign: "center" }}>
                            <div>
                                <img className="img-fluid" style={{}} src={require("../Assets/Images/location1.png")} />
                            </div>

                            <p style={{ fontSize: "18px", fontWeight: "600" }}>Registered office</p>
                            <p style={{ color: "#7A7878" }}>K37/A, Basement, near Kailash Colony metro station, New Delhi, South Delhi - 110048</p>

                        </div>
                        <div className="col-md-3" style={{ textAlign: "center" }}>
                            <div>
                                <img className="img-fluid" style={{}} src={require("../Assets/Images/location.png")} />
                            </div>

                            <p style={{ fontSize: "18px", fontWeight: "600" }}>Noida office</p>
                            <p style={{ color: "#7A7878" }}>A-78,Block A, Sector 4, Noida, Gautam Buddh Nagar, Uttar Pradesh 201301.</p>

                        </div>
                        <div className="col-md-3" style={{ textAlign: "center" }}>
                            <div>
                                <img className="img-fluid" style={{}} src={require("../Assets/Images/call us.png")} />
                            </div>

                            <p style={{ fontSize: "18px", fontWeight: "600" }}>Call us</p>
                            <p style={{ color: "#7A7878" }}>0120 4321739<br></br> +91 9643451369</p>

                        </div>
                        <div className="col-md-3" style={{ textAlign: "center" }}>
                            <div>
                                <img className="img-fluid" style={{}} src={require("../Assets/Images/mail us.png")} />
                            </div>

                            <p style={{ fontSize: "18px", fontWeight: "600" }}>Email us</p>
                            <p style={{ color: "#7A7878" }}>info@indotechs.in <br></br>hello@enbill.in</p>

                        </div>

                    </div>

                </div>
                <div className="row mapdiv" style={{ width: "100%", backgroundColor: "#F5F5F5", padding: "3%", paddingBottom: "8%" }}>
                    <div className="col">
                        <div style={{ textAlign: "center" }}>
                            <p style={{ color: "#7A7878", font: "normal normal bold 16px/22px Open Sans" }}>
                                Get in Touch
                            </p>
                        </div>
                        <form>
                            <input onChange={getName} className={isActive ? 'bg-salmon' : ''} placeholder="Your name*" required style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "2% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", height: "8%" }}></input>
                            <input onChange={getEmail} className={isActive2 ? 'bg-salmon' : ''} placeholder="Your email*" required style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "2% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", height: "8%" }}></input>
                            <input onChange={getPhonenumber} className={isActive3 ? 'bg-salmon' : ''} type="text" max="10" maxLength="10" placeholder="Your phone number*" required style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "2% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", height: "8%" }}></input>
                            <div style={{ marginLeft: "15px", marginTop: "5px" }}>
                                <p style={{ font: "normal normal normal 24px/33px Open Sans", color: "#7A7878" }}>Write a note for us, and we will make the best demo for your bussiness</p>
                            </div>

                            <textarea
                                onChange={getMessage}
                                className={isActive4 ? 'bg-salmon2' : 'inp'} id="secinp" rows="3" col="3" placeholder="message" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "2% 5%", boxShadow: "0px 3px 6px #00000040", margin: "5px", backgroundColor: "white" }}

                            ></textarea>
                            <a onClick={mail} className="ms-2" style={{ display: "block", backgroundColor: "#11884F", color: "white", borderRadius: "16px", fontSize: "19px", boxShadow: "0px 3px 6px #00000040", padding: "3% 30%", textDecoration: "none" }}>
                                Request a demo
                            </a>

                        </form>

                    </div>
                    <div className="col
                     mt-5 ms-md-4 me-5 
                      ">

                        <div className="google-map-code ms-md-5" style={{ marginTop: "2%", marginLeft: "1%" }}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.561538535211!2d77.3184337141551!3d28.582926293077193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce459de07d77d%3A0xad67b7f2ebc8d366!2sA-78%2C%20Block%20A%2C%20Sector%204%2C%20Noida%2C%20Uttar%20Pradesh%20201301!5e0!3m2!1sen!2sin!4v1664347140110!5m2!1sen!2sin" width="100%" height="730" frameborder="0" style={{ border: "1px dashed #EE7A37", opacity: "0.8" }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>

                    </div>

                </div>

            </div>
            <Footer></Footer>
        </>
    )
}
export default ContactUs; 