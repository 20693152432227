import './Component.css'
import { useState } from 'react'
import { useNavigate,useLocation } from 'react-router-dom';

const Header = () => {
    const {pathname} = useLocation();
    let navigate = useNavigate();
    const [drop, setDrop] = useState(false);

    return (
        <>
            <nav class="navbar navbar-expand-lg navbar-light px-5 row" style={{ boxShadow: "0px 1px 3px #000000BA", width: "100%", margin: "0" }}>
                <div class="container-fluid">
                    <a class="navbar-brand px-3 " href="/" >
                        <img src={require("../Assets/Images/logoenbil.png")} />
                        <span style={{ color: "#EE7A37" }}>EnBill</span>
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 "style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
                            <li class="nav-item px-3" style={{
                                //    maxWidth: "200px",
                                //     height: "50px"
                            }}>
                                <a className={(pathname === '/EnbillMerchantPage') ? 'active' : 'nav-link'} style={{}}  aria-current="page" href="/EnbillMerchantPage">ENBILL MERCHANT</a>
                            </li>
                            <li class="nav-item px-3" style={{
                                //   maxWidth: "200px",
                                //     height: "50px"
                            }}>
                                <a style={{}} className={(pathname === '/EnbillUserPage') ? 'active' : 'nav-link'} href="/EnbillUserPage" >ENBILL FOR USER</a>
                            </li>
                            <li class="nav-item px-3 " style={{
                                //    maxWidth: "200px",
                                //     height: "50px"
                            }}>
                                <a style={{}} className={(pathname === '/EnbillCareerPage') ? 'active' : 'nav-link'} href="/EnbillCareerPage">CAREERS</a>
                            </li>
                            <li class="nav-item dropdown px-3" style={{
                                // maxWidth: "200px",
                                // height: "50px"
                                position: "relative"
                            }}>
                                <a style={{ cursor: "pointer" }} class="nav-link" aria-expanded="false" onClick={() => setDrop(!drop)}>
                                    COMPANY
                                </a>
                                {drop ? <div style={{ position: "absolute", backgroundColor: "white",  width: "100%",right:"1px" }}>
                                    <div style={{}}>
                                        <a style={{ fontSize: "12px" }} className={(pathname === '/AboutUs') ? 'active' : 'nav-link'} href="/AboutUs">About us</a>
                                        <div style={{ height: "2px ", width: "100%", backgroundColor: "gray" }}></div>
                                        <a style={{ fontSize: "12px" }} className={(pathname === '/ContactUs') ? 'active' : 'nav-link'} href="/ContactUs">Contact Us</a>


                                    </div>
                                </div> : null}
                            </li>
                            {/* <li class="nav-item px-3" style={{
                                //    maxWidth: "200px",
                                //     height: "50px"
                            }}>
                                <a style={{}} class="nav-link" href="#">BLOGS</a>
                            </li> */}
                            {/* <li class="nav-item px-3" style={{
                                // maxWidth: "300px",
                                // height: "50px"
                            }}>
                                <a style={{}} className={(pathname === '//EnbillInvestorRelation') ? 'active' : 'nav-link'} href="/EnbillInvestorRelation">INVESTOR RELATIONS</a>
                            </li> */}

                        </ul>

                    </div>
                </div>
            </nav>


        </>
    )
}
export default Header;