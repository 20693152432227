import Header from "../components/Haeder";
import Footer from "../components/Footer";
import React, { useEffect, useState } from "react";
import RangeSlider from 'react-bootstrap-range-slider';
import './Career.css';
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const EnbillCareerPage = () => {
    const [value, setValue] = React.useState(50);
    const navigate = useNavigate();
    const[filelink,setFileLink]=useState("")
    const [name, setName] = useState(null);
    const getName = (val) => {
        setName(val.target.value)
      
    }

    const [address, setAddress] = useState(null);
    const getAddress = (val) => {
        setAddress(val.target.value)
     
    }

    const [city, setCity] = useState(null);
    const getCity = (val) => {
        setCity(val.target.value)
       
    }

    const [postcode, setPostcode] = useState(null);
    const getpostcode = (val) => {
        setPostcode(val.target.value)
       
    }

    const [phonenumber, setPhonenumber] = useState(null);
    const getPhonenumber = (event) => {
        setPhonenumber(event.target.value)
       
    }

    const [message, setMessage] = useState(null);
    const getMessage = (val) => {
        setMessage(val.target.value)
       
    }

    const [email, setEmail] = useState(null);
    const getEmail = (val) => {
        setEmail(val.target.value)
       
    }
    const[file,setFile]=useState("");
   
    
    const handleChange=(event)=>{
       
              setFile(event.target.files[0])
        
      
    }
   
   
 

    console.log("setFile", file)

   function handleSubmit(phonenumber){
    if(name===null){
        alert("Please enter your name")
    }else if(phonenumber===null){
        alert("Please enter your number")
    }else if(email===null){
        alert("Please enter your email")
    }else if(file===null){
    alert("Please upload your cv")
    }
    else{
    const url =`http://192.168.1.27:8000/get_enbill_in_file/?phone=${phonenumber}`;
    const formData = new FormData();
    console.log(file,"this is file")
    formData.append('file',file);
    formData.append('fileName',file.name);
    const config ={
        headers:{
            'content-type':'multipart/form-data',
        },
    };
    

    axios.post(url,formData,config).then((response)=>{
        console.log(response.data);
        if(response.data.code===200){
         
            setFileLink(response.data.data.link)    
             
        }else{
            console.log("errorrr")
        }
    });
   }}
   
    useEffect(() => {
        var   ebody = 'Name:%20' + name + '%0D%0A' + 'Address:%20' + address+ '%0D%0A' + 'City:%20' + city + '%0D%0A' + 'Postcode:%20' + postcode + '%0D%0A' + 'Phone Number:%20' + phonenumber + '%0D%0A' + 'Email:%20' + email + '%0D%0A' + 'File:%20' + filelink + '%0D%0A' + 'Message:%20' + message;

        
        if(filelink && filelink != null) {
            if(name===null){
                alert("Please enter your name")
            }else if(phonenumber===null){
                alert("Please enter your number")
            }else if(email===null){
                alert("Please enter your email")
            }else if(file===null){
            alert("Please upload your cv")
            }
            else{
                var add = address;
    
            window.location.href = "mailto: hello@enbill.in?body=" + ebody
          
            ;
            }
        }
    },[filelink])
   console.log("succsessfulll", file)



    return (

        <>
            <Header></Header>
            <div className="row" style={{ padding: "5%", backgroundColor: "#F5F5F5", width: "100%" }}>
                <div className="col" style={{ textAlign: "center" }}>
                    <p style={{ color: "#EE7A37", font: "normal normal 600 50px/60px Source Serif Pro" }}>We're Enbill.</p>
                    <div style={{ textAlign: "left", marginLeft: "8%" }}>
                        <p className="mt-3" style={{ font: "normal normal 600 50px/60px Source Serif Pro" }}>“Choose a job you love, and you will never have to work a day
                            in your life." —Confucius</p>
                        <p style={{ font: "normal normal 600 50px/60px Source Serif Pro" }}> shop owners and small businesses.</p>
                    </div>


                </div>
            </div>
            <div className="row px-5 px-md-0" style={{ width: "100%", marginTop: "10px", backgroundColor: "#F5F5F5", display: "flex", justifyContent: "center", paddingBottom: "2%" }}>
                <div style={{ textAlign: "left", padding: "1% 5%" }}>
                    <p style={{ color: "#EE7A37", font: "normal normal medium 35px/90px Roboto", fontSize: "35px", fontWeight: "600" }}>Current</p>
                    <p style={{ color: "#7A7878", font: "normal normal medium 25px/90px Roboto", fontSize: "25px", fontWeight: "600" }}>Opening</p>
                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "0px 3px 30px #EE7A3724", padding: "1%" }}>
                    <h4 style={{ color: "#002147", marginTop: "10%" }}>Product Designer</h4>
                    <p style={{ color: "#7A7878", marginTop: "8%" }}>Full-time or Contract</p>
                    <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "0px 3px 30px #EE7A3724", padding: "1%" }}>
                    <h4 style={{ color: "#002147", marginTop: "10%" }}>Product Designer</h4>
                    <p style={{ color: "#7A7878", marginTop: "8%" }}>Full-time or Contract</p>
                    <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "0px 3px 30px #EE7A3724", padding: "1%" }}>
                    <h4 style={{ color: "#002147", marginTop: "10%" }}>Product Designer</h4>
                    <p style={{ color: "#7A7878", marginTop: "8%" }}>Full-time or Contract</p>
                    <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "0px 3px 30px #EE7A3724", padding: "1%" }}>
                    <h4 style={{ color: "#002147", marginTop: "10%" }}>Product Designer</h4>
                    <p style={{ color: "#7A7878", marginTop: "8%" }}>Full-time or Contract</p>
                    <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "0px 3px 30px #EE7A3724", padding: "1%" }}>
                    <h4 style={{ color: "#002147", marginTop: "10%" }}>Product Designer</h4>
                    <p style={{ color: "#7A7878", marginTop: "8%" }}>Full-time or Contract</p>
                    <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "0px 3px 30px #EE7A3724", padding: "1%" }}>
                    <h4 style={{ color: "#002147", marginTop: "10%" }}>Product Designer</h4>
                    <p style={{ color: "#7A7878", marginTop: "8%" }}>Full-time or Contract</p>
                    <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "0px 3px 30px #EE7A3724", padding: "1%" }}>
                    <h4 style={{ color: "#002147", marginTop: "10%" }}>Product Designer</h4>
                    <p style={{ color: "#7A7878", marginTop: "8%" }}>Full-time or Contract</p>
                    <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "0px 3px 30px #EE7A3724", padding: "1%" }}>
                    <h4 style={{ color: "#002147", marginTop: "10%" }}>Product Designer</h4>
                    <p style={{ color: "#7A7878", marginTop: "8%" }}>Full-time or Contract</p>
                    <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "0px 3px 30px #EE7A3724", padding: "1%" }}>
                    <h4 style={{ color: "#002147", marginTop: "10%" }}>Product Designer</h4>
                    <p style={{ color: "#7A7878", marginTop: "8%" }}>Full-time or Contract</p>
                    <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "0px 3px 30px #EE7A3724", padding: "1%" }}>
                    <h4 style={{ color: "#002147", marginTop: "10%" }}>Product Designer</h4>
                    <p style={{ color: "#7A7878", marginTop: "8%" }}>Full-time or Contract</p>
                    <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>

                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button style={{ minWidth: "180px", backgroundColor: "#EE7A37", color: "white", width: "13%", borderRadius: "22px", padding: "8px 10px", border: "1px solid #EE7A37", boxShadow: "0px 3px 6px #00000029", marginTop: "5%", fontSize: "16px" }}>View all Openings</button>

                </div>


            </div>
      
            <div className="row" style={{ padding: "1% 3%", width: "100%" }}>
                <div className="col-md">
                    <div>
                        <img className="img-fluid" src={require("../Assets/Images/careerlogo1.png")} />
                    </div>
                    <div>
                        <p style={{ color: "#000000", fontSize: "18px" }}>Enthusiasm and Passion</p>
                        <p style={{ color: "#000000", opacity: "70%", fontSize: "16px" }}>We are looking for candidates who
                            are enthusiastic and passionate about what they do. Their
                            successes should shine through during the interview. People
                            who love their work often stay at companies longer than those
                            who work for the sake of the salary. Enthusiasm is an excellent
                            employee trait. Enthusiastic and outgoing employees are often
                            helpful to a business.</p>

                    </div>


                </div>
                <div className="col-md">
                    <div>
                        <img className="img-fluid" src={require("../Assets/Images/logocareer3.png")} />
                    </div>
                    <div>
                        <p style={{ color: "#000000", fontSize: "18px" }}>Honesty and integrity</p>
                        <p style={{ color: "#000000", opacity: "70%", fontSize: "16px" }}>We trust honest employees to do the
                            right thing and make the right decisions in the organisation's best
                            interests. Many situations in the workplace can create ethical and
                            moral dilemmas for employees.</p>

                    </div>


                </div>
                <div className="col-md">
                    <div>
                        <img className="img-fluid" src={require("../Assets/Images/logocareer4.png")} />
                    </div>
                    <div>
                        <p style={{ color: "#000000", fontSize: "18px" }}>Problem-solving abilities</p>
                        <p style={{ color: "#000000", opacity: "70%", fontSize: "16px" }}>Employees who work on something
                            until it’s solved or complete and who bring their best efforts to
                            solving problems are considered good employees.</p>

                    </div>


                </div>
                <div className="col-md">
                    <div>
                        <img className="img-fluid" src={require("../Assets/Images/logocareer6.png")} />
                    </div>
                    <div>
                        <p style={{ color: "#000000", fontSize: "18px" }}>Being a team player</p>
                        <p style={{ color: "#000000", opacity: "70%", fontSize: "16px" }}>Most jobs require teams to work together;
                            others need employees to interact with clients and occasional
                            outside contractors. The ability to collaborate and work pleasantly
                            and effectively with others is a key part of nearly any job. In order to
                            achieve a common goal, it is an asset to any company.</p>

                    </div>


                </div>

            </div>
            <div className="row" style={{ backgroundColor: "#F5F5F5", padding: "1% 3%", width: "100%" }}>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <p style={{ color: "#EE7A37", font: "normal normal medium 35px/90px Roboto", fontSize: "35px" }}>Join us at EnBill and do what you love.</p>


                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <div>
                                <img className="img-fluid" src={require("../Assets/Images/careerlogo8.png")} />
                            </div>
                            <div>
                                <p style={{ color: "#000000", fontSize: "18px", fontWeight: "500" }}>Is EnBill a fit for you?</p>
                                <p style={{ color: "#000000", opacity: "70%", fontSize: "16px" }}>EnBill welcomes individuals who are passionate with a zest for
                                    hard work and teamwork. We encourage candidates with original
                                    ideas and enthusiasm for their careers. Join us if you’re persistent
                                    and believe in not giving up, because here at EnBill we believe
                                    that “Knowing is not enough; we must apply. "Wishing is not
                                    enough; we must do.” Johann Wolfgang Von Goethe</p>

                            </div>

                        </div>

                    </div>

                </div>
                <div className="col-md">
                    <div>
                        <img className="img-fluid" src={require("../Assets/Images/logocareer7.png")} />
                    </div>

                </div>

            </div>
            <div className="row" style={{ width: "100%", padding: "1% 3%", width: "100%" }}>
                <div style={{ fontSize: "35px", fontWeight: "600", textAlign: "left", padding: "1px 13%" }}>
                    <p style={{ color: "#EE7A37" }}>Would you like</p>
                    <p style={{ color: "#7A7878" }}>To join us?</p>

                </div>
                <div className="col-md">

                    <div style={{ padding: "1% 5%" }}>
                        <form>
                            <input onChange={getName} placeholder="Your name*" name="name" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", }}></input>
                            <input onChange={getAddress} placeholder="Your Address" name="address" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", }}></input>
                            <input onChange={getCity} placeholder="City" name="city" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", }}></input>
                            <input onChange={getpostcode} placeholder="Postcode" name="postcode" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", }}></input>
                            <input onChange={getPhonenumber} placeholder="Phone number*" name="number" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", }}></input>
                            <a  onClick={()=>{handleSubmit(phonenumber); }} type="submit" name="targetname" className="ms-2" style={{ display: "block", backgroundColor: "#11884F", color: "white", borderRadius: "16px", fontSize: "19px", boxShadow: "0px 3px 6px #00000040", padding: "3% 30%", textDecoration: "none" }}>
                                Submit Your CV
                            </a>
                        </form>


                    </div>


                </div>

                <div className="col-md">
                    <div style={{ padding: "1% 5%" }}>
                        
                             <input onChange={getEmail} placeholder="Your email*" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", }}></input>

                        {/* <div  style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "10px 10px", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", textAlign:"left"}}>   
                            <div class="upload-btn-wrapper">
                            <button class="btn">Choose File</button>
                            <input type="file" name="myfile" />
                        </div>
                        </div> */}

                        <input onChange={handleChange}  accept="application/pdf" type="file" id="choose_file" placeholder="Your CV*" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", color: "#7A7878" }}></input>
                         



                        <textarea
                        onChange={getMessage}
                            className="inp" id="secinp" rows="3" col="3" placeholder="message" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "2% 5%", boxShadow: "0px 3px 6px #00000040", margin: "5px", backgroundColor: "white" }}

                        ></textarea>
                      
                       
                    </div>

                </div>

            </div>
            <Footer></Footer>

        </>
    )
}

export default EnbillCareerPage