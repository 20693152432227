import Header from "../components/Haeder";
import Footer from "../components/Footer";
import FooterLandingPage from "../components/FooterLandingPage";
import { useState } from "react";
import Card from "../components/Card";
import React from 'react'
import Lottie from 'react-lottie-player'

const EnbillMerchantPage = () => {
    const [showCard1, setShowCard1] = useState(false);
    const [showCard2, setShowCard2] = useState("");
    const [showCard3, setShowCard3] = useState("");
    const [showCard4, setShowCard4] = useState("");
    const [showCard5, setShowCard5] = useState(false);
    const [showCard6, setShowCard6] = useState("");
    const [showCard7, setShowCard7] = useState("");
    const [showCard8, setShowCard8] = useState("");
    const [showCard9, setShowCard9] = useState(false);
    const [showCard10, setShowCard10] = useState("");

    return (
        <>
            <Header></Header>
            <div className="row" style={{ padding: "5%", backgroundColor: "#F5F5F5", width: "100%" }}>

                <div className="col-lg-8 " style={{ padding: "5%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "baseline" }}>
                    <h4 style={{ fontSize: "1.8rem", textAlign: "left" }}> One Stop Solution For All Your Business Needs</h4>
                    <p className="mt-4" style={{ textAlign: "left", maxWidth: "480px", marginRight: "20px" }}>EnBill helps to connects local shops to their local Grahak's.
                        We support merchants to bring their offline shops online with
                        minimum effort. EnBill acts like a bridge between
                        shopkeepers and customers to increase a shop's online
                        visibility.
                        EnBill offers features like booking, online promotion,
                        catalogue, billing, Udhaar management, and other shop
                        management tools, to make the financial aspect of the
                        business swift and convenient.</p>
                    <a href="https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant" target="_blank" className="mt-4 btns" style={{ backgroundColor: "#EE7A37", color: "#fff", textDecoration: "none", padding: "8px 25px", borderRadius: "28px", border: "none", border: "1px solid #EE7A37 " }} >DOWNLOAD NOW</a>
                </div>
                <div className="col-lg-4 " >
                    <img className="img-fluid" style={{ height: "331px", width: "177px", transform: "rotate(-8deg)" }} src={require("../Assets/Images/image 2.png")} />
                </div>

            </div>
          
            <div>
                <p style={{ color: "#EE7A37", font: "normal normal bold 20px/26px Roboto", marginTop: "10px!important" }}>Know App Features</p>
            </div>
            <div className="row" style={{ padding: "1% 3%", width: "100%" }}>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo1.png")} />
                    </div>
                    <p style={{ color: "#EE7A37", font: "normal normal bold 17px/25px Noto Sans CJK KR" }}>Online Visibility</p>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins" }}>Increase foot traffic as your shop becomes visible online. Now
                        you can promote and compete on the same field as malls, luxury
                        brands, or bigger shops. Show your inventory in our catalogue and
                        let the world know what you have to offer.</p>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo2.png")} />
                    </div>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo3.png")} />
                    </div>

                </div>

            </div>
            <div className="gradiantbar" style={{ height: "30px", width: "100%", opacity: "39%" }}>

            </div>
            <div className="row" style={{ padding: "3% 5%", width: "100%" }}>
                <div className="col-md" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo4.png")} />
                    </div>

                </div>
                <div className="col-md">
                    <div  >
                        <img className="img-fluid" src={require("../Assets/Images/mlogo5.png")} />
                    </div>
                    <p style={{ color: "#EE7A37", font: "normal normal bold 17px/25px Noto Sans CJK KR" }}>Reconnect with Customers</p>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins" }}>Once a customer, always a customer!
                        Add your customers and reconnect with them on your own.
                        Record your customers’ bookings and generate their E-Bills.</p>


                </div>

            </div>
            <div className="gradiantbar" style={{ height: "30px", width: "100%", opacity: "39%" }}>

            </div>
            <div className="row" style={{ padding: "3% 5%", width: "100%" }}>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo1.png")} />
                    </div>
                    <p style={{ color: "#EE7A37", font: "normal normal bold 17px/25px Noto Sans CJK KR" }}>Promotion</p>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins" }}>Now promotion is as easy as forwarding messages. EnBill gives
                        you a variety of templates to choose from and distribute them to
                        your customers with a single click.</p>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo2.png")} />
                    </div>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo3.png")} />
                    </div>

                </div>

            </div>
            <div className="gradiantbar" style={{ height: "30px", width: "100%", opacity: "39%" }}>

            </div>
            <div className="row" style={{ padding: "3% 5%", width: "100%" }}>
                <div className="col-md" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo4.png")} />
                    </div>

                </div>
                <div className="col-md">
                    <div  >
                        <img className="img-fluid" src={require("../Assets/Images/mlogo5.png")} />
                    </div>
                    <p style={{ color: "#EE7A37", font: "normal normal bold 17px/25px Noto Sans CJK KR" }}>Digital Galla</p>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins" }}>Keep your passbook and cashbook in your pocket. Records every
                        transaction and updates your accounts instantly. Now you can
                        focus on your business while we take care of your accounts.</p>


                </div>

            </div>
            <div className="gradiantbar" style={{ height: "30px", width: "100%", opacity: "39%" }}>

            </div>
            <div className="row" style={{ padding: "3% 5%", width: "100%" }}>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo1.png")} />
                    </div>
                    <p style={{ color: "#EE7A37", font: "normal normal bold 17px/25px Noto Sans CJK KR" }}>Udhaar</p>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins" }}>Keep an authentic list of you debited customers and their
                        accounts. We will send constant reminders to customers as the day
                        to return the money comes by while you can just sit back and
                        relax!</p>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo2.png")} />
                    </div>

                </div>
                <div className="col-md" style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo3.png")} />
                    </div>

                </div>

            </div>
            <div className="gradiantbar" style={{ height: "30px", width: "100%", opacity: "39%" }}>

            </div>
            <div className="row" style={{ padding: "3% 5%", width: "100%" }}>
                <div className="col-md">
                    <p style={{ color: "#EE7A37", font: "normal normal medium 39px/60px Poppins", fontSize: "39px", textAlign: "left" }}>Digital Bills</p>
                    {/* <p style={{ color: "#7A7878", font: "normal normal medium 39px/60px Poppins", fontSize: "39px", textAlign: "left" }}>Mini Digital Mall</p> */}
                    <div style={{ height: "3px", width: "40%", backgroundColor: "#000000", opacity: "25%" }}></div>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins", textAlign: "left", marginTop: "10px" }}>The hassles of handwritten bills are removed by easily generating digital bills with just a few taps.
                        Digital billing gives a lot of benefits to the merchant, like:<br></br>
                        • Time and labour savings<br></br>
                        • Cost-cutting measures<br></br>
                        • Increased cash flow<br></br>
                        • Increased customer satisfaction and loyalty<br></br>
                        • Simplified, More Accurate Account Reconciliation and Reporting</p><br></br>
                    <button onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant", "_blank")} className="anibtn" style={{ backgroundColor: "#EE7A37", color: "white", borderRadius: "116px", padding: "10px 30px", border: "none", position: "relative" ,marginBottom:"10px"}}>Get Started <span className="aniarrow">&#8594;</span></button>

                </div>
                <div className="col" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo6.png")} />
                    </div>

                </div>

            </div>
            <div className="row gradiantbar" style={{ padding: "1% 5%", width: "100%" }}>
                <div className="col-md">
                    <p style={{ color: "#160637", font: "normal normal medium 28px/21px Poppins", fontSize: "26px", textAlign: "left", marginTop: "20px" }}>Do More For </p>
                    <p style={{ color: "#FE5858", font: "normal normal medium 28px/21px Poppins", fontSize: "26px", textAlign: "left", marginTop: "-15px" }}>Your Business</p>
                    <div style={{ height: "3px", width: "40%", backgroundColor: "#000000", opacity: "25%", marginBottom: "10%" }}></div>

                </div>
                <div className="col-md" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a href="https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant" target="_blank" className="anibtn" style={{textDecoration:"none", backgroundColor: "#EE7A37", color: "white", borderRadius: "116px", padding: "10px 30px", border: "none", position: "relative" ,marginBottom:"5px"}}>Get Started <span className="aniarrow">&#8594;</span></a>
                       
                </div>
                <div className="col-md">

                </div>

            </div>
            <div className="row" style={{ padding: "2% 5%", width: "100%" }}>
                <div className="col-md" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div  >
                        <img className="img-fluid" style={{}} src={require("../Assets/Images/mlogo7.png")} />

                    </div>

                </div>
                <div className="col-md">
                    <p style={{ color: "#EE7A37", font: "normal normal medium 39px/60px Poppins", fontSize: "39px", textAlign: "left" }}>Inventory Control </p>
                    {/* <p style={{ color: "#7A7878", font: "normal normal medium 39px/60px Poppins", fontSize: "39px", textAlign: "left" }}>Read Of Paper Bill</p> */}
                    <div style={{ height: "3px", width: "40%", backgroundColor: "#000000", opacity: "25%" }}></div>
                    <p style={{ color: "#8989A2", font: "normal normal normal 22px/40px Poppins", textAlign: "left", marginTop: "10px" }}>Inventory control will assist your business in knowing the shortfall and quantities to be ordered
                        considering the net stock available. It ensures that enough stocks are maintained to meet customer
                        needs at any point in time. We will help you to<br></br>
                        • Improve the accuracy of inventory orders<br></br>
                        • Improve warehouse productivity<br></br>
                        • Save time and money!<br></br>
                        • Improve customer retention and engagement</p>
                    <button onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant", "_blank")} className="anibtnleft" style={{ backgroundColor: "#EE7A37", color: "white", borderRadius: "116px", padding: "10px 30px", border: "none", position: "relative" }}> <span className="aniarrowleft">&#8592;</span> Get Started</button>



                </div>

            </div>


            <FooterLandingPage></FooterLandingPage>
        </>
    )
}

export default EnbillMerchantPage