import React from 'react';
import './App.css';
import EnbillLandingPage from './pages/EnbillLandingPage';
import EnbillMerchantPage from './pages/EnbillMerchantPage';
import EnbillUserPage from './pages/EnbillUserPage';
import EnbillCareerPage from './pages/EnbillCareerPage';
import EnbillInvestorRelation from './pages/EnbillInvestorRelation';
import AboutUs from './pages/AboutUs';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import ContactUs from './pages/ContactUs';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const App = () => {

  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={< EnbillLandingPage />}></Route>
          <Route exact path='/EnbillMerchantPage' element={< EnbillMerchantPage />}></Route>
          <Route exact path='/EnbillUserPage' element={< EnbillUserPage />}></Route>
          <Route exact path='/EnbillCareerPage' element={< EnbillCareerPage />}></Route>
          <Route exact path='/EnbillInvestorRelation' element={< EnbillInvestorRelation />}></Route>
          <Route exact path='/AboutUs' element={< AboutUs />}></Route>
          <Route exact path='/ContactUs' element={< ContactUs />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
