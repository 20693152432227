const FooterLandingPage = () => {
    return (
        <>
      
            <div className="row" style={{ width: "100%" }}>
                <div className="col" style={{ padding: "3%" }}>

                    <div style={{ backgroundColor: "#EE7A37", borderRadius: "12px", padding: "2% 5%", boxShadow: "0px 3px 6px #00000029" }}>
                        <div className="row" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <div className="col-md"

                                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div>
                                    <img style={{}} src={require("../Assets/Images/report.png")} />

                                </div>

                            </div>
                            <div className="col-md-10" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                <p className="ms-md-3" style={{ font: "normal normal normal 71px/90px Roboto", color: "white", fontSize: "71px", textAlign: "center" }}>Your money stays safe.</p>
                                <p style={{ color: "white", font: "normal normal medium 22px/31px Roboto", textAlign: "center", fontSize: "22px" }}>EnBill protects your money with the best-in-class security systems that help minimize frauds</p>

                            </div>

                        </div>

                    </div>





                </div>


            </div>
            <div className="row" style={{ padding: "5%", backgroundColor: "#F5F5F5", boxShadow: "rgba(0, 0, 0, 0.3) 0px 4px 9px", width: "100%" }}>
                <div className="col" style={{ marginLeft: "5%" }}>
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>Account</p>
                    <p  onClick={()=> window.open("http://merchant.enbill.in/", "_blank")} style={{cursor:"pointer", fontSize: "16px", color: "#545D63" }}>Sign In</p>
                    <p style={{ fontSize: "16px", color: "#545D63" }}>Register</p>
                </div>
                <div className="col-5">
                    <a href="/AboutUs" style={{ fontWeight: "600", fontSize: "16px",textDecoration:"none",color:"#172026" }}>Our Story </a>

                    <a href="/EnbillCareerPage" style={{display:"block",textDecoration:"none", fontSize: "16px", color: "#545D63",marginTop:"15px"}}>Careers</a>
                </div>
                <div className="col social_media" style={{display:"flex" ,alignItems:"center",flexDirection:"column"}}>
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>See All Apps</p>
                    <a href="https://play.google.com/store/apps/details?id=com.indotechs.enbillformerchant" target="_blank" style={{ color: "#EE7A37", border: "1px solid #11884F", backgroundColor: "white", borderRadius: "11px", padding: "5px 10px",display:"block",width:"150px"}}>Download Now</a>
                    <div style={{ paddingTop: "25px" }}>
                        <a href="https://www.instagram.com/enbillbharat/" target="_blank" className="mt-4 btns">             <img style={{ padding: "5px" }} src={require("../Assets/Images/instagram.png")} /></a>
                        <a href="https://www.facebook.com/EnBillBharat" target="_blank" className="mt-4 btns">   <img style={{ padding: "5px" }} src={require("../Assets/Images/facebook.png")} /> </a>
                      
                        <a href="https://twitter.com/Enbillbharat" target="_blank" className="mt-4 btns">  <img style={{ padding: "5px" }} src={require("../Assets/Images/twitter.png")} /></a>
                    </div>

                </div>
            </div>
            <div className="row" style={{ padding: "1% 5%",width:"100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", }}>
                        <img className="img-fluid" src={require("../Assets/Images/logoenbil.png")} style={{height:"50px",marginTop:"5px"}}/>
                        <div>
                               <p style={{ marginTop: "15px" }}>EnBill</p>
                        </div>
                     <div style={{marginRight:"60px"}}>
                         <p style={{ marginTop: "20px", marginLeft: "5px",width:"100%",color:"#C3CCD3",font:"normal normal medium Raleway" ,fontSize:"12px"}}>© Copyright@EnBill-2022-AllRights Reserved.</p>
                     </div>
                       
                    </div>
                    <div style={{ display: "flex", paddingRight: "9%" }}>
                        <a href="" style={{ marginTop: "15px", color: "#172026" }}>Terms of Use</a>
                        <a href="" style={{ marginTop: "15px", marginLeft: "30px", color: "#172026" }}>Privacy Policy</a>

                    </div>

                </div>
            </div>
        </>
    )
}
export default FooterLandingPage;